<template>
  <div>重庆市区县融媒体中心市级技术平台使用手册下载</div>
</template>

<script>
import { onMounted } from "vue";

export default {
  name: "DownloadUseManual",
  setup() {
    onMounted(() => {
      window.location.href = "https://www.cqgdrm.com/download/重庆市区县融媒体中心市级技术平台使用手册.pdf";
    });
  }
};
</script>

<style scoped>
</style>